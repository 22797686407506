import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter10 from '../common/src/assets/image/charity/specter/kapitola_10_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Desiata kapitola: Podkopávanie právneho systému | Ako duch komunizmu vládne nášmu svetu"
           description="Desiata kapitola: Podkopávanie právneho systému"
           image={imageChapter10}/>
      <h1>Desiata kapitola: Podkopávanie právneho systému</h1>
      <h2>1. Právo a viera</h2>
      <p>Právo je mocnou silou spravodlivosti, ktorá potvrdzuje dobro a trestá zlo. Tí, ktorí píšu zákony, musia chápať čo je dobré a čo zlé. Z hľadiska viery majú tieto kritériá božský pôvod. Zákony, ktoré riadia ľudskú spoločnosť, vychádzajú z posvätných písiem a svätých učení.</p>
      <p>Chammurapiho zákonník v starovekom Babylone je jedným z prvých písaných zákonníkov v ľudskej histórii, ktoré boli kedy nájdené. Je vyrytý na kamennej tabuli a nad kódexom je pozoruhodná scéna: Šamaš, boh slnka a spravodlivosti, odovzdáva zákony kráľovi Chammurapimu. Ide o vyobrazenie boha, ktorý udeľuje ľudskému vladárovi právomoc vládnuť svojmu ľudu pomocou práva.</p>
      <p>Desatoro Božích prikázaní v Starom zákone považovali Hebrejci za božský ako aj svetský zákon a táto tradícia sa stala základom právnej kultúry na Západe.</p>
      <p>Počnúc rímskymi cisármi v štvrtom storočí, cez východorímskeho cisára Justiniána I. a jeho nástupcov, až po prvého anglosaského kráľa Alfréda Veľkého, právny systém na Západe vždy čerpal inšpiráciu z Desatora Božích prikázaní a z kresťanského učenia.<a href="#_edn1" id="_ednref1">[1]</a></p>
      <p>Veriaci ľudia sa domnievajú, že na to, aby sa zákon považoval za právoplatný, musí zodpovedať božským normám dobra a&nbsp;zla, ako aj náboženskému učeniu. Myšlienka nenásilnej občianskej neposlušnosti v Spojených štátoch má svoj pôvod v ranej kresťanskej náuke. Keď rímsky cisár Gaius Caligula prikázal, aby boli sochy Caesara vsadené do stien chrámu v Jeruzaleme a aby kresťania uctievali rímskych bohov, kresťania sa rozhodli radšej podstúpiť ukrižovanie alebo upálenie na hranici, než aby poslúchli. Nasledovať Caligulov príkaz by znamenalo porušenie prvých dvoch prikázaní – cisár inými slovami požadoval, aby mal svetský zákon prednosť pred božským prikázaním, ktoré je posvätné a neporušiteľné.</p>
      <p>Vo všeobecnosti je možné rozdeliť Desatoro Božích prikázaní do dvoch kategórií. Prvé štyri opisujú vzťah človeka k Bohu a čo znamená patričná úcta k Bohu. Ďalších šesť určuje vzťahy medzi ľuďmi a vo svojej podstate odrážajú učenie o tom, že človek by mal milovať druhých ako seba samého. Úcta k Bohu je nevyhnutná a umožňuje ľudstvu zachovať si princípy čestnosti a spravodlivosti v nezmenenej podobe.</p>
      <p>To isté platilo i v dávnej Číne, kde boli zákony uvedené do platnosti cisárskym dekrétom. Cisár alebo „Syn nebies“ musel nasledovať prozreteľnosť a princípy Neba a Zeme. Toto je „Tao“ alebo Cesta, ktorú ľuďom priniesol Lao-c’ a Žltý cisár. Tung Čung-šu, konfuciánsky učenec z dynastie Chan, prehlásil: „Veľkosť Taa pochádza z Nebies. Nebesia sa nikdy nezmenia, a rovnako tak ani Tao.“<a href="#_edn2" id="_ednref2">[2]</a> V starovekom čínskom ponímaní neznamenajú „Nebesia“ prírodné sily, ale predstavujú Božstvo. Viera v Tao nebies tvorí morálne základy čínskej kultúry. Čínsky legislatívny systém odvodený z tejto viery ovplyvňoval Čínu po tisícky rokov.</p>
      <p>Americký znalec práva Harold J. Berman veril, že právo existuje v súlade so všeobecnými princípmi spoločenskej morálky a viery. Dokonca aj keď oddelíme cirkev od štátu, obe sú od seba vzájomne závislé. Koncept spravodlivosti a zákonnosti musí v každej spoločnosti prameniť z toho, čo je posvätné.<a href="#_edn3" id="_ednref3">[3]</a> Moderný právny systém si zachováva mnoho aspektov náboženského obradu, ktorý posilňuje jeho moc.</p>
      <h2>2. Právo ako nástroj tyranie v komunistických režimoch</h2>
      <p>Komunistické strany sú antiteistické kulty. Ich cieľom je prerušiť väzby, ktoré spoločnosť spájajú s kultúrou zdedenou od predkov a s tradičnými hodnotami. Vo svojich legislatívnych princípoch nebudú nikdy nasledovať učenia spravodlivých bohov. Nikdy nebolo realistické očakávať, že sa komunistické strany budú snažiť o zachovanie spravodlivosti.</p>
      <h3>a) Politika štátneho teroru mimo rámec zákona</h3>
      <p>Kresťania tradične hlásali, že človek má milovať druhých ako seba samého. Konfuciánske učenie hovorí, že dobrosrdečný človek miluje ostatných. Láska sa v týchto učeniach neobmedzuje iba na úzky koncept lásky medzi mužom a ženou alebo lásky medzi rodinnými príslušníkmi či priateľmi. Láska tiež zahŕňa dobrotivosť, milosrdenstvo, spravodlivosť, súcit, nezištnosť a iné cnosti. S touto kultúrnou základňou je zákon nielen posvätný, ale stelesňuje aj podstatu lásky v ľudskej spoločnosti.</p>
      <p>Žiadny právny systém nedokáže obsiahnuť všetky možné formy sporov a vyniesť nad všetkými z nich rozsudok. Takže zákony musia zohľadniť subjektivitu všetkých strán. Sudca sa musí držať ducha zákona a vyniesť verdikt, ktorý sa riadi princípom láskavosti.</p>
      <p>Ježiš v Jeruzalemskom chráme napomínal farizejov za ich pokrytectvo, pretože napriek tomu, že prísne dodržiavali slová Mojžiša, ignorovali cnosti vyžadované zákonom, ako je spravodlivosť, milosrdenstvo a pravdivosť. Sám Ježiš uzdravoval aj cez šabat a stretával sa s pohanmi, pretože to, na čom mu záležalo, bola láskavosť obsiahnutá v učeniach, a nie iba ich doslovný význam.</p>
      <p>Komunizmus naproti tomu vychádza z nenávisti. Nielenže nenávidí Boha, ale nenávidí aj kultúru, životný štýl a tradície, ktoré pre ľudstvo ustanovili nebesia. Marx vyjadril veľmi jasne svoju túžbu zničiť seba i celý svet. Svojej budúcej manželke napísal: „Jenny, pokiaľ dokážeme spojiť naše duše, s pohŕdaním hodím svoju rukavicu do tváre sveta a potom budem kráčať jeho rozvalinami ako stvoriteľ!“<a href="#_edn4" id="_ednref4">[4]</a></p>
      <p>Sergej Gennadijevič Nečajev, komunistický terorista z cárskeho Ruska, napísal vo svojej brožúre Revolučný katechizmus, že revolucionár „pretrhol všetky putá, ktoré ho spájajú so sociálnym poriadkom a&nbsp;s civilizovaným svetom a všetkými jeho zákonmi, s morálkou a&nbsp;zvykmi a so všetkými jeho všeobecne prijatými konvenciami“, a „pokiaľ by sa s nimi i napriek tomu stotožnil, je to iba preto, aby ich rýchlejšie zničil.“<a href="#_edn5" id="_ednref5">[5]</a></p>
      <p>Nečajev použil pre svoju víziu sveta, v ktorom neplatia žiadne zákony, duchovný pojem „katechizmus“. Poukázal tým na sektársku povahu komunizmu, ktorý pohŕda ľudstvom a božstvom. „Ten, kto prechováva akékoľvek sympatie k tomuto svetu, nie je revolucionár“, napísal.</p>
      <p>Lenin vyjadril podobný názor: „Diktatúra je vláda založená priamo na sile a nie je obmedzená žiadnymi zákonmi. Revolučná diktatúra proletariátu je vláda získaná a udržiavaná za použitia násilia proletariátu proti buržoázii, vláda, ktorá sa neobmedzuje žiadnym zákonom.“<a href="#_edn6" id="_ednref6">[6]</a></p>
      <p>Používanie politickej moci na zabíjanie, mučenie a zavádzanie kolektívnych trestov bez právnych zábran nie je nič iné ako štátny terorizmus a ide o prvý krok, ku ktorému dôjde po nastolení komunistických režimov.</p>
      <p>Mesiac potom, čo v roku 1917 boľševici zvrhli ruskú vládu, boli pri politických čistkách v dobe politického boja zabitých stovky tisíc ľudí. Boľševici založili Všeruskú mimoriadnu komisiu pre boj s kontrarevolúciou a sabotážou, ktorej skrátený názov bol Čeka, a&nbsp;dostala právomoc k hromadným popravám. V rokoch 1918 až 1922 zabili čekisti bez súdneho procesu viac ako 2 milióny ľudí.<a href="#_edn7" id="_ednref7">[7]</a></p>
      <p>Alexander Nikolajevič Jakovlev, bývalý minister propagandy a člen politbyra a sekretariátu Komunistickej strany Sovietskeho zväzu, napísal, že iba v 20. storočí zomrelo v Rusku na následky vojny, hladu a represií spolu 60 miliónov ľudí. Jakovlev na základe záznamov z verejných archívov odhadoval, že v čase sovietskych perzekučných kampaní bolo zabitých 20 až 30 miliónov ľudí. Politbyro Sovietskeho zväzu zriadilo v roku 1987 výbor, ktorého členom bol i Jakovlev a ktorý mal za úlohu preskúmať justičné omyly sovietskej vlády. Potom, čo preskúmal tisícky spisov, Jakovlev napísal: „Mám obavu, s ktorou si už dlho neviem rady. Zdá sa, že páchatelia týchto zverstiev sú ľudia, ktorí sú duševne pomätení, ale obávam sa, že takéto vysvetlenie celý problém iba príliš zjednoduší.“<a href="#_edn8" id="_ednref8">[8]</a></p>
      <p>Otvorene povedané, Jakovlev videl, že zverstvá napáchané v dobe komunizmu nepochádzajú z nejakého náhleho popudu, ale že boli starostlivo naplánované. Tieto zločiny neboli spáchané v mene vyššieho dobra vo svete, ale z hlbokej nenávisti k&nbsp;životu samotnému. Strojcovia komunizmu nepáchali zverstvá z nevedomosti, ale zo zlomyseľnosti.</p>
      <p>&nbsp;</p>
      <h3>b) Neustále sa meniace normy pre to, čo je a&nbsp;čo nie je správne</h3>
      <p>Kým komunizmus ignoruje právny štát a dopúšťa sa celoštátneho teroru, navonok sa snaží presvedčiť západné krajiny o tom, že mu ide o dodržiavanie práva. Robí to preto, aby takto mohol prostredníctvom obchodného a hospodárskeho partnerstva, kultúrnej výmeny a geopolitickej spolupráce preniknúť do slobodnej spoločnosti a rozvrátiť ju. Napríklad Čínska komunistická strana takto na začiatku čínskych reforiem v roku 1979 schválila „zákon o trestnom konaní“, zdanlivo na posilnenie justície. Tento zákon sa však v skutočnosti nikdy nedodržoval.</p>
      <p>Podľa Marxa je zákon produktom rozporu medzi triedami a nástrojom, ktorý stelesňuje vôľu vládnucej triedy. Zákony komunistickej strany nepochádzajú ani od Boha, ani zo skutočnej lásky k ľudom, ani z túžby mať spravodlivú spoločnosť. To na čom záleží sú záujmy vládnucej skupiny – teda komunistickej strany v danej krajine. Ako sa menia záujmy a ciele strany, tak sa menia aj jej zákony.</p>
      <p>Prirodzene, hneď ako sa ČKS chopila moci, prijala filozofiu triedneho boja a začala okrádať všetkých občanov. Následne vyhlásila zákon o „kontrarevolučnej činnosti“, ktorý sa vzťahoval na všetkých, ktorí sa postavili politike strany. Kontrarevolucionárov trestali komunisti odsúdením do väzenia alebo zastrelením.</p>
      <p>Potom, čo skončila s masovým okrádaním a zaviedla verejné vlastníctvo, potrebovala komunistická strana spôsob, ako si uchovať to, čo nakradla. Presunula svoje priority na hospodársky rast a zaviedla zákony, ktoré chránili súkromný majetok. V skutočnosti nešlo o nič iné ako ochrániť vlastné záujmy strany. Napríklad nútené demolácie domovov po celej Číne v záujme rozvojových projektov ukazujú, že režim naďalej porušuje právo na súkromné ​​vlastníctvo.</p>
      <p>V marci roku 1999 čínska komunistická strana oznámila, že je potrebné „riadiť krajinu na základe zákonov“.<a href="#_edn9" id="_ednref9">[9]</a> O niekoľko mesiacov neskôr začalo celoštátne prenasledovanie praktizujúcich Falun Gongu a bol zriadený Úrad 610, mimosúdny orgán podobný gestapu, ktorý mal za úlohu viesť brutálnu kampaň. Tomuto úradu bola daná právomoc obchádzať všetky zákony a súdne postupy. To mu umožnilo manipulovať aparátom verejnej bezpečnosti a súdnym systémom, aby mohol prenasledovať Falun Gong.</p>
      <p>Strana si pravidelne vymýšľa nových nepriateľov, aby obnovila svoju tyranskú vládu nad čínskym ľudom. Ciele prenasledovania sa neustále menia: od kampaní proti veľkostatkárom a kapitalistom cez masaker študentov na námestí Nebeského pokoja v roku 1989 až k potlačeniu stúpencov Falun Gongu a obhajcov ľudských práv.</p>
      <p>Preto museli čínski komunisti pravidelne meniť i zákon. Za dobu viac ako sedemdesiat rokov, kedy strana vládne, vyhlásila štyri ústavy, z ktorých posledná prešla od svojho zavedenia v roku 1982 štyrmi revíziami. Strana získala skúsenosti z početných politických kampaní a zneužíva právo na zakrytie svojich motívov a činov, pričom niekedy sa ani neobťažuje túto kamufláž použiť.</p>
      <p>&nbsp;</p>
      <h3>c) Ignorovanie ústavy</h3>
      <p>Ústava Čínskej ľudovej republiky je písaná rozvláčnym jazykom, aby strana ukázala, že je odhodlaná dodržiavať zásady právneho štátu a medzinárodné normy. V praxi sa však táto ústava nikdy nedodržuje a základné práva, ako sloboda prejavu, viery a zhromažďovania, nie sú v skutočnosti nijako chránené.</p>
      <p>Podľa teórie marxizmu zákon odráža vôľu vládnucej triedy a nie skutočnú spravodlivosť. Komunistická strana túto teóriu nasleduje, keď vytvára a novelizuje zákony, aby potlačila svojich nepriateľov a presadila svoju vôľu v spoločnosti. V takomto zriadení je každý, kto sa odváži spochybniť „vôľu vládnucej triedy“, teda každý, kto je proti záujmom komunistickej strany, vystavený súdnemu stíhaniu ako triedny nepriateľ. Či už sú to nezamestnaní, vojaci prepustení do civilu, roľníci, ktorým vyvlastnili pôdu, ľudskoprávni advokáti alebo jednoducho tí, ktorí sotva prežívajú.</p>
      <p>Právnici, ktorí pôsobia v komunistických krajinách, sú si plne vedomí toho, že spôsob, akým sú písané zákony, necháva strane vždy priestor pre jej vlastné výklady. Akonáhle sa právnik odváži citovať zákon a argumentovať v prospech spravodlivosti, sudca a prokurátor ho umlčia tým, že budú hovoriť o údajnom duchu zákona. Dokonca nehanebne tvrdia, že súd je pod vedením komunistickej strany a musí sa riadiť jej príkazmi. Bez ohľadu na to, čo si títo jednotliví súdni zamestnanci myslia, to, čo hovoria, skutočne odráža v&nbsp;akej podobe existuje duch zákona v komunistických režimoch.</p>
      <p>V čínskom súdnom systéme, v priebehu výsluchov prenasledovaných priaznivcov Falun Gongu, môžete z úst sudcov počuť napríklad tieto výroky: „Prečo tu hovoríte o zákonoch? Mňa zaujíma iba politika.“ „Strana nedovoľuje obhajobu. Slová vodcov sú zákon.“ „Komunistická strana riadi súdy, takže musíme nasledovať stranícku líniu.“&nbsp; „V otázkach Falun Gongu nie je potrebný žiadny právny postup.“ „Nehovorte mi tu o svedomí.“<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Anglický filozof Francis Bacon kedysi napísal: „Jeden nespravodlivý verdikt spôsobí viac škody ako mnoho nespravodlivých príkladov, pretože tie pokazia iba potok, zatiaľ čo nespravodlivý rozsudok otrávi prameň.“<a href="#_edn11" id="_ednref11">[11]</a></p>
      <p>Zákony komunistickej strany, ktoré sú tvárne a používané selektívne, nemajú žiadnu posvätnosť, z ktorej by plynula legitímna autorita. V uplynulom storočí „duch zákona“, ktorý riadi právny systém strany, priniesol nespočetné množstvo nespravodlivostí a smrť 100 miliónov nevinných obetí – krvavý dlh, ktorý žiadny predstaviteľ komunizmu nebude môcť nikdy odčiniť.</p>
      <p>„Vrah musí zaplatiť svojím životom, rovnako ako dlžník peniazmi,“ hovorí jedno čínske príslovie. Keby komunistická strana skutočne presadzovala zákony, musela by sa zodpovedať za svoju krvavú históriu.</p>
      <p>&nbsp;</p>
      <h2>3. Ako komunizmus deformuje právne normy na Západe</h2>
      <p>Rovnako ako politika, ekonomika, vzdelávanie a&nbsp;ďalšie aspekty spoločnosti, ani západný právny štát neunikol infiltrácii a&nbsp;rozvráteniu zo strany ducha komunizmu. V&nbsp;komunistických krajinách sa manipuluje právnymi predpismi ako nástrojom na udržanie režimu&nbsp;pri&nbsp;moci, posilnenie ideológie komunistickej strany a&nbsp;na&nbsp;potláčanie občanov. V slobodných krajinách je cieľom ducha komunizmu použiť zákon na&nbsp;narušenie štandardov dobra a&nbsp;zla a podkopanie tradičnej viery a&nbsp;morálnych základov práva. Jeho cieľom je tiež zmocniť sa legislatívy, polície a&nbsp;súdov, aby mohol zaviesť svoje démonické normy do praxe.</p>
      <p>Vplyv ducha komunizmu na právny štát je možné nájsť na celom Západe. Keďže Spojené štáty sú vodcom slobodného sveta, v&nbsp;nasledujúcom odseku sa zameriame na narušovanie právnych inštitúcií v&nbsp;USA.</p>
      <p>&nbsp;</p>
      <h3>a) Podkopávanie morálnych základov práva</h3>
      <p>Spolu s&nbsp;tým, ako komunistické strany a&nbsp;ich rozliční nasledovníci vo svete presadzovali ateizmus a&nbsp;evolučnú teóriu, spojenie medzi právom a&nbsp;božími princípmi začalo byť vytrvalo odmietané. Božská podstata ducha zákona a&nbsp;pôvodná rola práva, teda udržovanie férovosti a&nbsp;spravodlivosti, sa začali premieňať na vyjadrovanie politických názorov a radikálnych ideológií. Právo sa z&nbsp;veľkej časti zredukovalo na nástroj pomsty, arbitráže, vyjednávania a&nbsp;prideľovania výhod. To otvorilo dvere ľuďom, ktorí boli otrávení komunizmom, aby presadzovali zákony, ktoré podkopávajú spoločnosť a vedú ľudstvo k zničeniu.</p>
      <p>Liberalizmus a&nbsp;progresivizmus odmietajú tradičnú vieru a&nbsp;morálne súdy, zatiaľ čo morálku považujú za svetskú dohodu, ktorá sa mení s rozvojom spoločnosti. Napríklad manželstvo je považované za jednoduchú zmluvu medzi dvoma ľuďmi, ktorí sú ochotní vyjadriť svoj záväzok voči sebe a už nie ako sľub zložený pred Bohom. Uznanie manželstva medzi osobami rovnakého pohlavia je založené na oficiálne udávanom argumente o slobode a pokroku – argumente, ktorý je ohýbateľný a nevyhnutne povedie k narušeniu práva. Oddelenie tradičnej morálky od spravodlivosti bolo demonštrované na prípade potratu, ktorý sa dostal v roku 1992 pred Najvyšší súd v USA. Traja sudcovia prehlásili: „Niektorí z nás ako jednotlivci považujeme potrat za urážku tých najzákladnejších princípov morálky, ale to naše rozhodovanie nemôže ovplyvniť. Našim záväzkom je vymedziť slobodu pre všetkých a nie prikazovať druhým náš vlastný mravný kódex.“<a href="#_edn12" id="_ednref12">[12]</a></p>
      <p>Inými slovami, zákon uprednostňuje slobodu pred morálkou a sloboda a mravnosť sú oddelené. Avšak sloboda, tak ako ju vnímali otcovia zakladatelia v Amerike, je „samozrejmý“ princíp ustanovený Bohom alebo „Stvoriteľom“, ako to vyjadruje Deklarácia nezávislosti. Odmietanie univerzálnych meradiel, ktoré Stvoriteľ stanovil pre ľudstvo, za účelom rozširovania takzvaných „slobôd“, je metódou, ktorú používa diabol, aby pokrivil právo a uvrhol ľudstvo do úpadku.</p>
      <h3>b) Ovládnutie legislatívy a&nbsp;promulgácia</h3>
      <p>Než zákon nadobudne účinnosť, prechádza mnohými fázami, vrátane návrhu, podpory politikov, legislatívneho procesu a zavedenia do praxe. Môže tiež dôjsť k súdnemu rozhodnutiu ohľadom jeho zákonnosti. V priebehu tohto procesu majú jednotlivci alebo skupiny v akademických, mediálnych, právnych a dokonca v zábavných kruhoch možnosť ovplyvniť pripravovanie a schvaľovanie zákona. Duch komunizmu našiel svojich zástupcov v celej spoločnosti, aby mu pomohli legislatívny proces ovládnuť. Najrôznejší lobisti sa zo všetkých síl snažili naplniť vládne úrady ľavicovými sympatizantmi. V súdnictve sa z nich stali sudcovia, prokurátori a ďalší pracovníci zodpovední za výkon spravodlivosti.</p>
      <p>Prezidenti v&nbsp;USA urobia všetko, čo je v ich silách, aby za sudcu Najvyššieho súdu menovali osoby rovnakého zmýšľania, alebo použijú svoju výkonnú moc na to, aby právny systém obišli. Liberálni americkí prezidenti udeľovali v dejinách vždy viac milostí ako republikáni, ale Obamova vláda znížila tresty 1385 odsúdencom a udelila amnestiu 212 osobám, čo je najvyšší počet od vlády Harryho Trumana.<a href="#_edn13" id="_ednref13">[13]</a>&nbsp; V&nbsp;jednom zo svojich posledných počinov pred opustením Bieleho domu znížil prezident Obama tresty 209 osobám a&nbsp;64 ďalším udelil milosť. Väčšina z&nbsp;tých, ktorým bola udelená milosť, boli nenásilní drogoví delikventi. Jedinou výnimkou bol muž, ktorý bol uznaný vinným zo zverejnenia 700 tisíc tajných vojenských dokumentov. Vďaka prezidentovi bol trest mužovi znížený tak, že si z&nbsp;vymeraných 35 rokov odpykal necelých sedem rokov.<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>Prezident má síce na základe ústavy právo udeliť milosť podľa svojho uváženia, ale nadmerné použitie tejto právomoci pôsobí proti fungovaniu a&nbsp;účelu zákonov, t. j. trestať zlé skutky a&nbsp;podporovať poctivých občanov. V&nbsp;roku 1954 vtedajší senátor Lyndon B. Johnson, ktorý sa neskôr stal 36.&nbsp;prezidentom Spojených štátov amerických, zaviedol tzv.&nbsp;Johnson Amendment, novelu daňového zákona, ktorá zakazuje neziskovým organizáciám vrátane cirkví, aby sa venovali niektorým činnostiam. Prekročenie zákona môže znamenať zrušenie daňovej výnimky. V&nbsp;duchu tohto opatrenia niektoré kresťanské cirkvi dali pokyn svojim duchovným, aby sa pri&nbsp;kázaní vyhýbali istým témam, ako je homosexualita, potraty, eutanázia, výskum kmeňových buniek a&nbsp;tak ďalej.</p>
      <p>Duch komunizmu manipuloval tiež všetky politické zoskupenia v snahe zmeniť presadzovanie práva. Jeden okresný prokurátor z&nbsp;Kalifornie, privedený na svoju pozíciu progresívnymi sponzormi a&nbsp;politickými skupinami, vyhodil v&nbsp;prvom týždni svojho úradovania tridsaťjeden prokurátorov. Tento človek oznámil, že chce skoncovať s&nbsp;„masovým zatýkaním“, a&nbsp;nariadil svojim zvyšným podriadeným, aby prestali trestať ľudí za vlastníctvo marihuany. V&nbsp;ostatných štátoch prichádza k&nbsp;podobným situáciám. Predsedníčka odborov pre zástupcov okresných advokátov v&nbsp;okrese Los Angeles uviedla, že tento krok znamená to isté ako vyzývať štátnych zástupcov, aby si vyberali, ktoré zločiny budú trestať a&nbsp;ktoré nie. Podľa jej názoru ide o&nbsp;veľmi „šikmú plochu“, keď sú volení úradníci nabádaní k&nbsp;ignorovaniu zákonov, ktoré majú na základe svojej prísahy presadzovať.<a href="#_edn15" id="_ednref15">[15]</a></p>
      <p>Sudcovia majú tiež právomoc zrušiť príkazy z&nbsp;administratívnych oddelení. Napríklad imigračný zákon USA dáva prezidentovi právo v&nbsp;prípade mimoriadnych situácií zamietnuť cudzincom vstup do krajiny. Avšak niektorí sudcovia ovplyvnení liberálnymi myšlienkami považovali nedávny zákaz vstupu do krajiny osobám z&nbsp;určitých krajín za náboženskú diskrimináciu. Ich obštrukcie oneskorili zavedenie zákazu o&nbsp;štyri mesiace, až pokiaľ oprávnenosť prezidentského rozhodnutia nepotvrdil Najvyšší súd.</p>
      <p>Keďže právnici vo veľkej miere ovplyvňujú rozhodnutia súdu, politické zameranie právnických asociácií môže mať priamy dopad na to, či je dodržiavaný duch zákona. Zakladateľom jedného z takýchto právnických združení s celoštátnym zastúpením bol samozvaný socialista, ktorý verí vo verejné vlastníctvo a tvrdí, že jeho najvyšším cieľom je ustanoviť komunizmus.<a href="#_edn16" id="_ednref16">[16]</a>&nbsp;Táto asociácia sa chváli základňou o&nbsp;veľkosti desiatok tisícov členov v celej krajine a ročným rozpočtom v stovkách miliónov dolárov. Toto zoskupenie podáva súdne žaloby v prípadoch na podporu potratov, manželstiev osôb rovnakého pohlavia a práva homosexuálov na adopciu.</p>
      <p>Stúpenci liberalizmu a&nbsp;progresivizmu obsadzujú v USA kľúčové politické pozície a&nbsp;dominujú akademickej sfére, médiám i&nbsp;sociálnym hnutiam. To umožňuje duchovi komunizmu, aby využíval bezprecedentnú moc nad legislatívnymi a&nbsp;justičnými procesmi.</p>
      <p>&nbsp;</p>
      <h3>c) Prekrútené súdne výklady</h3>
      <p>Tradičné náboženstvá zdôrazňujú dôležitosť osobnej zodpovednosti. V&nbsp;Biblii, v&nbsp;knihe Ezechiel, je príklad otca a&nbsp;syna použitý ako podobenstvo na&nbsp;vyobrazenie dobrého a&nbsp;zlého. I keď ide o otca a syna, každý si nesie následky vlastného správania a nenesie zodpovednosť za činy ostatných. Ako sa píše v Biblii: „Človek zožne to, čo zasial.“ Rovnako tak Číňania veria, že dobro plodí dobro a zlo bude potrestané Nebesami.</p>
      <p>Sloboda znamená zodpovednosť. Človek má právo a&nbsp;slobodu zvoliť si svoje myšlienky, reč a činy. Musí byť tiež zodpovedný za to, čo si sám vyberie. Keď človek spácha zločin, mal by byť za to adekvátne potrestaný. To je princíp spravodlivosti. Liberálni sudcovia však vedú ľudí k tomu, aby svoju zodpovednosť zmenšovali a presúvali ju na prevládajúce spoločenské podmienky, ako sú ekonomická a rasová situácia, fyzické a duševné zdravie, vzdelanie a ďalšie demografické parametre, čím umožňujú zločincom, aby unikli zákonnému trestu.</p>
      <h4>Zákaz vyjadrovania viery na verejnosti</h4>
      <p>V&nbsp;americkej kultúre je Boh všade. Heslom národa je „Veríme&nbsp;Bohu“, čo je dokonca napísané aj&nbsp;na dolárovej bankovke. V&nbsp;Deklarácii nezávislosti Spojených štátov amerických stojí, že ľudské práva sú niečím, čo nám dal Stvoriteľ. Všetci americkí vládni činitelia, vrátane prezidenta a&nbsp;sudcov, prehlasujú pri&nbsp;svojej prísahe: „K tomu mi napomáhaj Boh.“ Najčastejšou záverečnou frázou prezidentských prejavov býva „Boh žehnaj Amerike“. Sľub vernosti, prednášaný v štátnych školách, popisuje USA ako „jeden národ pred Bohom“.</p>
      <p>Niektoré tieto tradície pretrvávajú už viac ako dvesto rokov a&nbsp;možno ich vystopovať takmer k&nbsp;času založenia Spojených štátov. Avšak v posledných šesťdesiatich rokoch sú neustále spochybňované komunistickými nasledovníkmi.</p>
      <p>Jedno celoštátne združenie právnikov sa snaží odstrániť Desatoro z&nbsp;verejných miest v&nbsp;celej krajine. K najznámejšiemu prípadu došlo v Montgomery, štát Alabama, kde toto právnické združenie v&nbsp;roku 2001 požiadalo o&nbsp;odstránenie tabule s&nbsp;vytesanými desiatimi prikázaniami. Táto tabuľa bola umiestnená v&nbsp;hale súdnej budovy. Skupina našla sudcu, menovaného demokratickým prezidentom, ktorý si prípad vypočul a v 76 stranovom rozsudku rozhodol v prospech právnického združenia.</p>
      <p>Niektoré z&nbsp;odôvodnení môžu znieť až&nbsp;smiešne. Sudca napríklad tvrdil, že „slávnostná atmosféra haly“, fresky za tabuľou a&nbsp;atmosféra tvorená obrazom okna s&nbsp;vodopádom dávajú dosť dôvodov na&nbsp;to, aby bolo Desatoro odstránené. Sudca tiež uviedol, že „zvažujúca sa vrchná časť“ dosky pripomína otvorenú Bibliu a&nbsp;divákom môže navodiť „pocit, že štát Alabama propaguje, schvaľuje, uprednostňuje alebo preferuje kresťanstvo“.<a href="#_edn17" id="_ednref17">[17]</a></p>
      <p>Už roku 1980 Najvyšší súd zakázal, aby sa Desatoro vyobrazovalo v štátnych školách. Toto rozhodnutie poslúžilo ako spúšťač celonárodného hnutia požadujúceho stiahnutie Desatora z verejných miest. V Utahu národné právnické združenie dokonca ponúklo odmenu tomu, kto nahlási neodstránené tabule.<a href="#_edn18" id="_ednref18">[18]</a>&nbsp;</p>
      <p>Istý oblastný odvolací súd v&nbsp;USA 26.&nbsp;júna 2002 rozhodol, že štátne školy majú zakázané organizovať prísahy členov školskej rady, pretože sa v nich vyskytujú slová „pred Bohom“. Tento rozsudok bol 14. júna 2004 zrušený Najvyšším súdom.<a href="#_edn19" id="_ednref19">[19]</a></p>
      <p>Ide o neutíchajúci právny boj. Americká štátna hymna, heslo, sľub vernosti, školské modlitby a ďalšie sú neustále pod paľbou ateistov a ľavicových aktivistov. Odkazy na „Boha“ alebo Stvoriteľa, ktoré sú ukotvené v Deklarácii nezávislosti, odrážajú božské princípy, na ktorých je založená ústava USA, a&nbsp;neporušujú práva Američanov. Verejné zobrazovanie Desatora a&nbsp;ďalších náboženských tabúľ zdôrazňuje hlbokú vieru amerického ľudu. Vzostup politických hnutí, ktoré sa pokúšajú zakázať verejné prejavy viery v&nbsp;Boha u&nbsp;národa založenom na silných duchovných tradíciách ukazuje, do akej miery komunizmus prenikol do právneho systému.</p>
      <h4>Pozmeňovanie ducha ústavy cez interpretáciu a&nbsp;precedenčné právo</h4>
      <p>Pri procese navrhovania americkej ústavy ustanovili otcovia-zakladatelia oddelenie moci, pričom justičná sféra pôvodne mala tú najmenšiu moc. Kongres (legislatívna sféra) má na starosti schvaľovanie zákonov, prezident (výkonná sféra) má na starosti zavádzanie a vynucovanie zákonov vytvorených Kongresom a Najvyšší súd (justičná sféra) nemá ani moc schvaľovať zákony, ani vládnuť.</p>
      <p>Keď Najvyšší súd riešil v roku 2002 prípad týkajúci sa „sľubu vernosti“, prieskumy ukázali, že 90 % Američanov bolo za zachovanie výrazu „pred Bohom“. V Snemovni reprezentantov bolo 416 kongresmanov za zachovanie frázy a iba traja sa vyjadrili proti.<a href="#_edn20" id="_ednref20">[20]</a> V Senáte bol výsledok 99 pre a nula proti.<a href="#_edn21" id="_ednref21">[21]</a> Hlasy Kongresu odrážali skutočný názor americkej verejnosti.</p>
      <p>Členovia Kongresu a prezident sú volenými zástupcami ľudu a ich volebné obdobie trvá od dvoch do šiestich rokov. Potom sa môžu uchádzať o znovuzvolenie. Keď sú verejnosť a hlavný prúd spoločnosti vedené božskými meradlami morálky, miera, do akej sa prezident a volení zástupcovia môžu odchýliť doľava, je obmedzená. Na druhej strane, sudcovia Najvyššieho súdu sa nemusia riadiť názorom verejnosti, pretože svoj post majú na doživotie. Týchto sudcov je iba deväť a je relatívne ľahšie ovplyvniť rozhodnutie týchto deviatich jednotlivcov ako zmeniť názor verejnosti.</p>
      <p>V Spojených štátoch si zmena ústavy vyžaduje podporu dvoch tretín Kongresu a troch štvrtín štátov. Vďaka týmto prísnym opatreniam je teda ťažké pozmeniť ústavu priamo. Stratégiou progresívcov teda nie je meniť ústavu priamo, ale nanovo ju interpretovať. Ústavu považujú za „živý“ a neustále sa „vyvíjajúci“ dokument. Využitím precedensu stanoveného Najvyšším súdom, snažia sa do zákonov zakódovať názory ľavice. Tým skryto manipulujú ústavou, čo sa rovná jej podkopávaniu.</p>
      <p>Božské ustanovenia už nie sú tým najvyšším princípom. Namiesto toho ústava dostáva na frak od liberálnych sudcov Najvyššieho súdu, pretože ich rozhodnutia sú konečné a musí ich rešpektovať aj prezident. Súdna sféra si medzi tromi sférami moci privlastňuje čoraz väčší podiel moci. A tak si v praxi sudcovia Najvyššieho súdu vydobyli čiastočnú legislatívnu a dokonca výkonnú moc.</p>
      <p>Rozhodnutie liberálnych sudcov Najvyššieho súdu prinieslo americkej spoločnosti mnoho vážnych následkov, ktoré sa budú ťažko naprávať. V súčasnosti môže Najvyšší súd pomocou súdnych pojednávaní nariadiť odstránenie Desatora z verejných škôl a priestorov, zmeniť postupy riešenia kriminálnych prípadov, zvyšovať dane, uznávať právo na potrat a manželstvo osôb rovnakého pohlavia, dovoliť publikovanie a vystavovanie pornografie, a tak ďalej.</p>
      <p>Narastajúca nadradenosť justície v kombinácii s rozhodnutiami liberálnych sudcov dali duchovi komunizmu dôležitý nástroj na dosiahnutie svojich plánov.</p>
      <p>&nbsp;</p>
      <h4>Propagácia obscénneho obsahu v mene slobody</h4>
      <p>60. roky 20. storočia boli pre americkú spoločnosť érou hlbokej transformácie. Ľavicoví študenti organizovali protivojnové hnutia, prišiel rokenrol, hippies, feministické hnutie, sexuálna revolúcia a ďalšie prúdy namierené proti tradícii. To všetko uvrhlo krajinu do zmätku.</p>
      <p>Predsedom Najvyššieho súdu bol v tej dobe liberál Earl Warren. Počas jeho predsedníctva vydal Najvyšší súd veľmi vplyvné a ďalekosiahle súdne rozhodnutia vrátane zákazu modlitieb v štátnych školách alebo povolenie publikácií, ktoré otvorene vyobrazujú sex. <a href="#_edn22" id="_ednref22">[22]</a> <a href="#_edn23" id="_ednref23">[23]</a></p>
      <p>Odborníčka na ústavné právo Phyllis Schlaflyová vo svojej knihe Nadradení ľudia: Tyrania sudcov a ako ju zastaviť (The Supremacists: The Tyranny of Judges and How to Stop It) uvádza štatistiky, ktoré ukazujú, že Najvyšší súd urobil medzi rokmi 1966 až 1970 spolu 34 rozhodnutí, ktoré zrušili rozhodnutia súdov nižšej inštancie zakazujúce obscénny obsah.<a href="#_edn24" id="_ednref24">[24]</a> Uznesenia Najvyššieho súdu neboli podpísané a väčšina z nich obsahovala vysvetlenie iba v jednom či dvoch riadkoch. Inými slovami, ani sami sudcovia sa neobťažovali svoje rozhodnutia odôvodniť.</p>
      <p>Hollywood v roku 1968 upustil od svojho produkčného kódexu a zrušil obmedzenia ohľadom obscénneho obsahu vo filme. Čoskoro nato nasledovala záplava rôznych druhov pornografických diel a dnes sme svedkami toho, ako tento obsah presiakol do každého kúta spoločnosti.</p>
      <p>Prvý dodatok k ústave zaručuje slobodu slova. Táto sloboda mala ľuďom zabezpečovať právo na slobodné vyjadrenie politických názorov, a nie prispievať k masovej výrobe pornografie a jej šíreniu.</p>
      <h4>Legalizácia drog</h4>
      <p>Keď sa svet 31. decembra 2017 pripravoval na privítanie nového roka, káblový spravodajský kanál CNN odvysielal zábery s reportérkou, ako fajčí marihuanu. Žena bola očividne pod vplyvom drogy a zdala sa dezorientovaná a akoby nevedela, čo sa okolo nej deje. Reportáž sa dočkala širokej kritiky.<a href="#_edn25" id="_ednref25">[25]</a></p>
      <p>V roku 1996 bolo v Kalifornii ako v prvom štáte USA schválené predpisovanie marihuany na lekárske účely. Ďalšie štáty na seba nenechali dlho čakať. Colorado a Washington legalizovali v roku 2012 marihuanu pre „rekreačné účely“. V týchto dvoch štátoch sú pestovanie, výroba a predaj marihuany dospelým osobám úplne legálne. Od tej doby došlo k jej legalizácii tiež v Kalifornii. V októbri 2018 schválila užívanie marihuany aj kanadská vláda.</p>
      <p>Okrem toho, že drogy vážne škodia ľudskému telu, sú tiež návykové. Akonáhle je človek závislý, ľahko sa vzdá morálnych zábran, aby sa dostal k ďalšej dávke. Tí, ktorí podporujú legalizáciu drog, sa domnievajú, že to zabráni čiernemu obchodu s drogami. Tvrdia, že legalizácia umožní prísnejšiu reguláciu drog a zníži výskyt zločinov spojených s výrobou a predajom drog.</p>
      <p>Vlády očakávajú, že legalizáciou drog zarobia miliardy dolárov na ich predaji. Ale nie je ťažké domyslieť si, že ako bude počet závislých osôb narastať, budú strácať chuť k práci, zhorší sa im zdravie, upadne produktivita a bohatstvo nazhromaždené spoločnosťou sa zmenší. Je preto zrejmé, že legalizácia drog nemôže z dlhodobého hľadiska navýšiť príjmy štátu. Okrem toho by sa posudzovanie toho, čo je dobré a čo zlé, nemalo riadiť ekonomickými ziskami, ale vyššími meradlami. Tradičné mravy považujú ľudskú formu za posvätnú a človeka za niekoho, kto bol stvorený na Boží obraz. Západné náboženstvo verí, že telo je chrámom Ducha Svätého, zatiaľ čo na Východe veria, že kultiváciou môže telo dosiahnuť stav Budhu alebo Taa. Užívanie drog je preto zneucťovaním človeka.</p>
      <p>Podľa správy denníka Los Angeles Times je jednou z kľúčových postáv lobujúcich za legalizáciu marihuany v USA istý majetný progresivista.<a href="#_edn26" id="_ednref26">[26]</a> V marci 2017 napísalo šesť senátorov list ministerstvu zahraničných vecí USA, kde požadovali, aby bol tento človek vyšetrovaný za to, že používa svoju nadáciu na propagovanie progresivizmu v zahraničí a na podkopávanie konzervatívnych vlád.<a href="#_edn27" id="_ednref27">[27]</a></p>
      <p>Legalizácia drog je ďalším krokom k tomu, aby ľudia stratili zábrany a boli odrezaní od nebies. Keď spoločnosť zažíva rozvrat a ekonomický úpadok, komunisti sa chopia príležitostí, aby získali politickú moc.</p>
      <p>&nbsp;</p>
      <h4>Legalizácia manželstva osôb rovnakého pohlavia</h4>
      <p>Schlaflyová vymenovala dvanásť metód, ktoré sudcovia používajú na podkopávanie spoločenskej morálky: prepisovanie ústavy, cenzúra uznávania Boha, zmena definície manželstva, podkopanie americkej suverenity, ohrozovanie vlastníckych práv, propagácia pornografie, podpora feminizmu, oslabovanie zložiek činných v trestnom konaní, vítanie nelegálnych imigrantov, zasahovanie do volieb, prevzatie kontroly nad rodičovskými právami a uvalenie daní.<a href="#_edn28" id="_ednref28">[28]</a></p>
      <p>V knihe Genesis sa píše o zničení Sodomy. Jedným zo zločinov, ktorých sa obyvatelia mesta dopustili, bolo oddávanie sa homosexualite. Tak vznikol výraz „sodomia“, ktorý znamená sexuálny styk medzi mužmi. Tí, ktorí majú základnú znalosť Biblie, vedia, že homosexualita je proti Božej vôli.</p>
      <p>V júni 2015 rozhodol Najvyšší súd pomerom hlasovania 5 ku 4, že manželstvo osôb rovnakého pohlavia je právom zaručeným ústavou.<a href="#_edn29" id="_ednref29">[29]</a> Po schválení uznesenia zmenil vtedajší americký prezident pozadie oficiálneho twitterového účtu Bieleho domu na dúhovú vlajku ako výraz podpory hnutia LGBT. Rozhodnutie Najvyššieho súdu zakázalo 13 štátom, ktoré manželstvo osôb rovnakého pohlavia nepovoľovali, aby tieto svoje zákony ďalej uplatňovali. V auguste 2015 odmietla istá úradníčka z okresu Rowan v Kentucky vydať homosexuálnemu páru potvrdenie o manželstve s odôvodnením, že je to proti jej viere. Neskôr bola uväznená na päť nocí za to, že neposlúchla príkaz amerického federálneho súdu, aby tieto dokumenty vydala.<a href="#_edn30" id="_ednref30">[30]</a> V skutočnosti súd porušil jej ústavné právo na slobodu presvedčenia. Bývalý guvernér Arkansasu a bývalý republikánsky kandidát na prezidenta Mike Huckabee komentoval prípad úradníčky a nazval rozhodnutie Najvyššieho súdu ohľadom manželstva „justičnou tyraniou“.<a href="#_edn31" id="_ednref31">[31]</a></p>
      <p>Do roku 2019 oficiálne uznalo či akceptovalo manželstvo osôb rovnakého pohlavia 28 krajín a teritórií. Patria sem rozvinuté západné štáty ako USA, Veľká Británia, Francúzsko, Nemecko, Španielsko, Nórsko, Dánsko, Fínsko, Švédsko, Portugalsko, Belgicko, Austrália, Nový Zéland a Kanada. Legalizovať správanie, ktoré sa odkláňa od tradičných morálnych hodnôt, je to isté, ako keby vláda a právo vychovávali ľudí k tomu, aby zradili mravné zásady a nepočúvali Božie prikázania.</p>
      <p>Pod vplyvom politickej korektnosti môže byť kritika chaosu, v ktorom sa naša spoločnosť teraz nachádza, či už ide o kritiku od jednotlivcov, občianskych združení či náboženských skupín, ľahko eskalovaná na politickú úroveň alebo do formy zákona, čo môže mať za následok obmedzovanie slobody prejavu či ďalšie tresty. Po legalizovaní nemorálneho správania sa tí, ktorí komentujú alebo kritizujú dané otázky, často stretávajú s obviňovaním z porušovania zákonov, napríklad tých, ktoré sa týkajú rodovej diskriminácie. Právo bolo prekrútené, aby sa stalo prostriedkom, ako udusiť schopnosť ľudí robiť rozhodnutia na základe morálnych zásad. Právo tak v podstate propaguje homosexualitu a nabáda ľudí, aby sa oddali nekonečným túžbam a zvrhlostiam.</p>
      <p>&nbsp;</p>
      <h3>d) Obmedzovanie trestného stíhania</h3>
      <p>Mnoho sudcov a legislatívnych úradov, ktoré sú pod vplyvom liberalizmu, zámerne obmedzujú legitímnu moc zložiek činných v trestnom konaní, čím prakticky zatvárajú oči pred zločinom. Cieľom ducha komunizmu je paralyzovať štátny aparát za účelom dosiahnutia spoločenského rozvratu, čo na oplátku vytvorí ospravedlnenie pre rozširovanie vlády alebo vyvolá potrebné podmienky pre prevrat či revolúciu.</p>
      <p>Mnoho amerických štátov schválilo krajne ľavicové zákony. Ako príklad spomeňme ustanovenia tzv. azylových štátov, ktoré často zakazujú federálnym imigračným úradníkom, aby zadržali ilegálnych imigrantov v&nbsp;miestnych väzeniach, vrátane tých, pre ktorých existuje platný zatykač. Miestna polícia má zakázané spolupracovať a komunikovať v prípadoch imigračného práva s federálnymi zložkami.</p>
      <p>Takéto zákony predstavujú pre verejnosť vážne bezpečnostné riziko. V júli 2015 zastrelil nelegálny imigrant José Inez García Zárate mladú ženu, ktorá sa prechádzala po móle v San Franciscu. Zárate nebol žiadny neznámy kriminálnik. V minulosti bol obžalovaný zo siedmich ťažkých zločinov, vrátane obchodovania s drogami, lúpeže alebo nezákonného prechovávania zbrane a celkom päťkrát ho deportovali. Kvôli azylovým zákonom San Franciska úradníci Zárateho prepustili z väzby tri mesiace pred osudovou streľbou, namiesto toho, aby ho odovzdali federálnym imigračným úradom, ktoré požadovali, aby bol vzatý do väzby či vyhostený z krajiny.</p>
      <p>Keď je zločinec postavený pred súd, obžaloba musí splniť extrémne prísne kritériá. Oficiálne je to kvôli ochrane zákonných práv podozrivého, ale výsledok je často ten, že kriminálnici zneužívajú medzery v zákonoch. Prefíkaní podozriví alebo tí, ktorí sa tešia vysokému postaveniu, ľudia, ktorí rozumejú právu a predpisom, či tí, ktorí si najmú schopných advokátov, môžu preťahovať súdny proces takmer donekonečna, čo justíciu stojí obrovské náklady. Môže byť veľmi ťažké doviesť vinných podozrivých k spravodlivosti.</p>
      <p>Pod vplyvom šíriacej sa „sexuálnej revolúcie“, verdikty v prípadoch sexuálnych zločinov často citujú zistenie z výskumu Alfreda Kinseyho či aktivistov, ktorí s ním sympatizujú. Všetko odôvodňujú tvrdením, že ujma spôsobená sexuálnym zneužitím je malá alebo že vôbec neexistuje. Mnoho takýchto prípadov bolo odbavených znížením trestov pre sexuálnych delikventov.<a href="#_edn32" id="_ednref32">[32]</a> Znížili sa tiež tresty mnohým bežným kriminálnikom a to kvôli údajnému znižovaniu rozpočtu alebo na základe práv väzňov. Skutočnou motiváciou je však použitie politickej korektnosti s cieľom oslabiť moc zákona, narušiť spoločenský poriadok a vydláždiť cestu pre ďalšie posilnenie vlády.</p>
      <p>Aby boli zákony spravodlivé, musia mať prísne tresty pre tých, ktorí spáchali neospravedlniteľné zločiny. Od dávnych čias bola vražda vždy trestaná smrťou. Dnes od tejto praxe však mnoho krajín odstúpilo s odvolávaním sa na „humanitu“, „toleranciu“ alebo domnelú úctu k životu.</p>
      <p>Pod vplyvom pokriveného liberalizmu a progresivizmu prikladajú niektorí jednotlivci prílišnú váhu právam trestancov, a to bez ohľadu na závažnosť zločinu, zatiaľ čo o obetiach sa podivne mlčí. Ak je vrah kŕmený a ubytovaný na náklady daňových poplatníkov, to, že prišiel o slobodu, je slabým zadosťučinením za smrť obete a traumu, ktorú to spôsobuje jej rodine. Mnoho vedcov v Spojených štátoch dospelo k záveru, že trest smrti je účinným nástrojom na odradenie od zločinu. David Muhlhausen, analytik z The Heritage Foundation, ktorý svedčil v roku 2007 pred justičným výborom senátu, uviedol, že trest smrti má odstrašujúci účinok a môže zachrániť životy. Traja profesori, vrátane Paula Rubina z Emory University, preskúmali v 90. rokoch kriminálne štatistiky za predchádzajúcich dvadsať rokov z 3000 miest v USA a dospeli k záveru, že „každá poprava má za následok v priemere o osemnásť menej vrážd“ – s možnou odchýlkou plus mínus 10“.<a href="#_edn33" id="_ednref33">[33]</a></p>
      <p>Dokonca aj akademici, ktorí sú proti trestu smrti, musia uznať, že má odstrašujúci účinok. Zatlačením konceptov slobody a zákonnosti do extrémov sa ľavici podarilo prekrútiť právo a ukradnúť mu jeho posvätnosť.</p>
      <p>&nbsp;</p>
      <h3>e) Používanie cudzích zákonov k oslabeniu americkej suverenity</h3>
      <p>Keď liberálni sudcovia nemôžu nájsť v americkej ústave formulácie, ktoré by podporili ich osobné názory, používajú na ospravedlnenie svojich argumentov zákony iných krajín.</p>
      <p>Napríklad v prípade Najvyššieho súdu Lawrence vs. Texas (2003), chceli liberálni sudcovia zrušiť texaský zákon zakazujúci plnoletým homosexuálom sexuálny styk, ale nemohli v ústave nájsť nič, čo by ich názor podporilo. Citovali teda „autoritatívny úrad“ mimo USA, ktorý hovorí, že homosexualita je „neoddeliteľnou súčasťou ľudskej slobody v mnohých ďalších krajinách“ a úspešne tak daný zákon zrušili. Tento prípad mal za následok zrušenie podobných zákonov v trinástich ďalších amerických štátoch.<a href="#_edn34" id="_ednref34">[34]</a></p>
      <p>Komunistické myšlienky sa vo svete rozšírili v rôznych podobách. Socialistické trendy naprieč Áziou a Európou sú ľahko rozoznateľné a majú veľký vplyv aj v Afrike a v Južnej Amerike. Štáty ako Zimbabwe alebo Venezuela síce nemajú v mene označenie „socialistická“ ale v praxi takými sú. Komunistickému vplyvu neunikla ani Kanada.</p>
      <p>Obchod a globalizácia priviedli Spojené štáty do užšieho kontaktu s inými krajinami. Aby liberálni sudcovia zaviedli prvky socializmu na domácej pôde, používajú ako ospravedlnenie to, že sa snažia prispôsobovať medzinárodným dohovorom, a potom použijú precedentné právo, aby pozmenili ducha americkej ústavy. Ak si ani Spojené štáty ako vodca slobodného sveta nedokážu udržať základné, tradičné mravné hodnoty, celý svet bude uvrhnutý do rúk komunizmu.</p>
      <h2>4. Obnovenie ducha zákona</h2>
      <p>Dnes je právo namierené proti božiemu učeniu, ktoré pre neho bolo pôvodne inšpiráciou. Z práva sa stal nástroj ducha komunizmu na pošliapanie morálnych základov ľudskej spoločnosti a ​​privedenie ľudstva na pokraj zániku. Legislatíva namierená proti tradíciám a morálke oslabuje schopnosť práva udržať spoločenský poriadok, čím zostáva ako jediné „riešenie“ posilnenie štátnej moci.</p>
      <p>Ľuďom by pod právnym systémom ovládaným duchom komunizmu zostali iba dve možnosti: buď odmietnuť nasledovať vládu a jej zvrátené zákony, alebo zradiť svoje morálne zásady a prispôsobiť sa. To prvé by v praxi znamenalo zánik práva, pretože ako povedal právny znalec Harold Berman: „Zákonom sa musí veriť, inak existujú iba nominálne.“<a href="#_edn35" id="_ednref35">[35]</a> Druhá možnosť znamená vydať sa na šikmú plochu morálneho úpadku, čo vytvorí zostupnú špirálu, v ktorej právo a spoločnosť súperia o to, kto sa ocitne na samom dne ako prvý. V každom prípade by spoločnosť ako celok už nemala z tohto démonického víru úniku.</p>
      <p>Kniha Nahý komunista (Naked Communist) z roku 1958 vymenováva 45 cieľov komunizmu s cieľom infiltrovať sa do Spojených štátov a oslabiť ich. Sedem z nich sa týka právneho systému.<a href="#_edn36" id="_ednref36">[36]</a></p>
      <p>16. cieľom je použiť rozhodnutia súdov k oslabeniu dôležitých amerických inštitúcií s tvrdením, že konanie týchto inštitúcií narúša občianske práva.</p>
      <p>24. cieľom je skoncovať so všetkými zákonmi, ktoré obmedzujú neslušný obsah, pod zámienkou, že ide o cenzúru porušujúcu slobodu slova a prejavu.</p>
      <p>29. cieľom je napadnúť ústavu ako chybnú, zastaranú či nezlučiteľnú s medzinárodnou praxou.</p>
      <p>33. cieľom je zrušiť všetky zákony, ktoré zasahujú do komunistického aparátu.</p>
      <p>38. cieľom je umožniť nepolicajným organizáciám, aby vykonávali zatýkanie. Všetky problémy so správaním sa majú posunúť pracovníkom s duševne chorými.</p>
      <p>39. cieľom je vydobyť si kontrolu nad odborom psychiatrie a zaviesť zákony o duševnom zdraví tak, aby bolo možné ovládať jednotlivcov, ktorí oponujú komunistickým cieľom.</p>
      <p>45. cieľom je zrušenie Connallyho dodatku, ktorý dáva Spojeným štátom právo uprednostňovať domácu jurisdikciu pred rozhodnutiami medzinárodných súdov. Tento cieľ má zabrániť USA v ochrane ich suverenity a prinútiť ich k tomu, aby sa riadili medzinárodnými inštitúciami, ako je Medzinárodný súdny dvor.</p>
      <p>Keď sa pozrieme na uvedené ciele a porovnáme ich s tým, čo už bolo dosiahnuté, je zrejmé, že komunizmus má silne vykročené k oslabeniu amerického práva a justície.</p>
      <p>Či už ide o štátnu politiku nenávisti v krajinách ovládaných komunistickými režimami, alebo o predpisy v západných krajinách, kde komunistická ideológia podkopala legislatívne a justičné inštitúcie, v oboch prípadoch je terčom duch zákona, to jest úcta k božským princípom a tradičnej morálke.</p>
      <p>Ak si nebudeme schopní udržať morálne normy určené božími prikázaniami ako kritérium pre určenie toho, čo je očividne dobré či zlé, spoločnosť bude aj naďalej prijímať zákony, ktoré zodpovedajú komunistickej ideológii, uprednostňujú zlé a potláčajú spravodlivé. Spoločnosť stratí božské princípy spravodlivosti a slobody a podľahne tyranii ducha komunizmu.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Harold J. Berman, The Interaction of Law and Religion (Nashville: Abingdon Press, 1974), 51-55.</p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Dong Zhongshu (Tung Čung-šu) 董仲舒, citácia z: Ban Gu (Pan Ku) 班固, „Dong Zhongshu zhuan“ 董仲舒 傳 [ „Kronika Tung Čung-šu“] v: Han Shu 漢書 [Kniha Han]. [V čínštine]</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Berman, The Interaction.</p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Karl Marx, citácia z: W. Cleon Skousen, The Naked Communist (Salt Lake City: The Ensign Publishing Co., 1962), 13.</p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Sergey Nechayev, The Revolutionary Catechism, (1869), Marxists Internet Archive (Marxistický internetový archív), navštívené 20. apríla 2020, <a href="https://www.marxists.org/subject/anarchism/nechayev/catechism.htm"></a><a href="https://www.marxists.org/subject/anarchism/nechayev/catechism.htm">https://www.marxists.org/subject/anarchism/nechayev/catechism.htm</a>.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Vladimír Lenin, „The Proletarian Revolution and the Renegade Kautsky: How Kautsky Turned Marx Into A Common Liberal“, z Vybraných spisov Lenina, prel. Jim Riordan (Moscow: Progress Publishers, 1974), 28: 227-325, Marxistický internetový archív, navštívené 20. apríla 2020, <a href="https://www.marxists.org/archive/lenin/works/1918/prrk/common_liberal.htm.">https://www.marxists.org/archive/lenin/works/1918/prrk/common_liberal.htm.</a></p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Li Yuzhen (Li Jü-čen) 李玉貞, Yi bu dianfuxing zhuzhuo: Ershi shiji Eguoshi 一部 顛覆性 著作: 二十 世紀 俄國 史 [Work of Insurrection: 20th-Century Russian History], (Beijing: Yanhuang Chunqiu, 2010 ). [V čínštine]</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Alexandr Nikolajevič Jakovlev, „Zhi Zhongguo Duzhe“ 致 中國 讀者 [ „Čínskym čitateľom“], v: Yibei kujiu - Eluosi de Buershiweikezhuyi he gaige yundong 一杯 苦酒 - 俄羅斯 的 布爾什維克 主義 和 改革 運動 [Horký pohár: boľševizmus a reformácie Ruska], prekl. Xu Kui (Sü Kchuej) 徐 葵 a kol. (Beijing: Xinhua chubanshe, 1999), 10. [v čínštine] Citovaný text je predslovom napísaným pre čínske vydanie Jakovlevovej knihy.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> 15. marca 1999, „Dodatok Ústavy Čínskej ľudovej republiky“, prijatý na druhom zasadnutí deviateho Národného ľudového zasadnutia ľudových zástupcov, vložil do Článku 5 ústavy túto formuláciu: „Čínska ľudová republika zavádza vládu práva a buduje socialistickú spoločnosť, ktorá je riadená zákonom.“ Pozri An Linxian (An Lin-sien) 安霖贤, Xianfa yuanze yu yi fa zhiguo 宪法 原则 与 依法 治国 [Ústavné princípy a riadenie krajiny podľa zákona], www.people.com.cn, 2. novembra 2006, http: //<a href="http://legal.people.com.cn/GB/43027/73487/73490/4990833.html">legal.people.com.cn/GB/43027/73487/73490/4990833.html</a>.</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Ouyang Fei (Ou-jang Fej) 歐陽 非, „Hongse huangtang huangyan lun“ 紅色 荒唐 言論 [ "Červené nezmysly"], Minghui.org, 8. januára 2015, <a href="http://www.minghui.org/mh/">http://www.minghui.org/mh/</a> articles / 2015/1/8 / 302850.html. [v čínštine]</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Francis Bacon, „Judikatúry“ v: Eseje, to jest Rady občianske a mravné (The Essays or Counsels, Civil and Moral of Francis Bacon, ed. Samuel Harvey Reynolds) (Oxford: Clarendon Press, 1890), internetový archív, navštívené 20. apríla 2020, <a href="https://archive.org/stream/essaysorcounsel00bacouoft/essaysorcounsel00bacouoft_djvu.txt"></a><a href="https://archive.org/stream/essaysorcounsel00bacouoft/essaysorcounsel00bacouoft_djvu.txt">https://archive.org/stream/essaysorcounsel00bacouoft/essaysorcounsel00bacouoft_djvu.txt</a>.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Planned Parenthood of Southeastern Pennsylvania v. Casey (Nos. 91-744, 91-902), Legal Information Institute, navštívené 20. apríla 2020, <a href="https://www.law.cornell.edu/supct/html/91-">https://www.law.cornell.edu/supct/html/91-</a> 744.ZO.html.</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Neil Eggleston, „President Obama Has Now Granted More Commutations Than Any President in This Nation's History“, Biely dom, 17. januára 2017, <a href="https://obamawhitehouse.archives.gov/blog/2017/01/17/president-">https://obamawhitehouse.archives.gov/blog/2017/01/17/president-</a> obama-has-now-granted-more-commutations-any-president-nations-history.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Gregory Korte, „Obama Commutes Sentence of Chelsea Manning in Last-Minute Clemency Push“, USA TODAY, 17. januára 2017, <a href="https://www.usatoday.com/story/news/politics/2017/01/17/">https://www.usatoday.com/story/news/politics/2017/01/17/</a> obama-commutes-sentence-chelsea-Manning / 96678814 /.</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Paige St. John a Abbie Vansickle, „Here's Why George Soros, Liberal Groups Are Spending Big to Help Decide Who's Your Next D.A.“, Los Angeles Times, 23. mája 2018, <a href="http://www.latimes.com/local/california/la-">http://www.latimes.com/local/california/la-</a> me-prosecutor-campaign-20180523-story.html.</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Kongres USA, Snemovňa, Affidavit of Roger N. Baldwin, Investigation of Un-American Propaganda Activities in the United States: Hearings before a Special Committee on Un-American Activities, 75th-78th Cong., 31. decembra 1938, 3081- 3082, internetový archív, navštívené 20. apríla 2020, <a href="https://archive.org/details/investigationofu193804unit/page/2448/mode/2up/search/Baldwin"></a><a href="https://archive.org/details/investigationofu193804unit/page/2448/mode/2up/search/Baldwin">https://archive.org/details/investigationofu193804unit/page/2448/mode/2up/search/Baldwin</a>.</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Myron H. Thompson, citované z: Phyllis Schlafly, The Supremacists: The Tyranny of Judges and How to Stop It (Minneapolis, MN: Richard Vigilante Books, 2006), 26-27.</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Phyllis Schlafly, „Pots of Gold Behind Crosses and Ten Commandments“, Eagle Forum, 23. júna 2004, <a href="http://eagleforum.org/column/2004/june04/04-06-23.html"></a><a href="http://eagleforum.org/column/2004/june04/04-06-23.html">http://eagleforum.org/column/2004/june04/04-06-23.html</a>.</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> Elk Grove Unified School District v. Newdow, 542 U.S. 1 (2004).</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Kongres USA, Snemovňa, Expressing the Sense of the House of Representatives that Newdow v. US Congress Was Erroneously Decided, and for Other Purposes, HR 459, 107th Cong., 1st sess., predložené a odsúhlasené v Snemovni 27. januára 2002 , <a href="https://www.congress.gov/bill/107th-congress/house-resolution/459"></a><a href="https://www.congress.gov/bill/107th-congress/house-resolution/459">https://www.congress.gov/bill/107th-congress/house-resolution/459</a>.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Kongres USA, Senát, A Resolution Expressing Support for the Pledge of Allegiance, S. 292, 107th Cong., 1st sess., predložené a odsúhlasené Senátom 24. júna 2002, <a href="https://www.congress.gov/bill/107th-congress/senate-resolution/292"></a><a href="https://www.congress.gov/bill/107th-congress/senate-resolution/292">https://www.congress.gov/bill/107th-congress/senate-resolution/292</a>.</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Schlafly, The Supremacists, 30.</p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> Na tom istom mieste, 58.</p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> Na tom istom mieste, 60-61.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> „CNN Revels in Pot Smoke during New Year's Eve Report from Denver“, Fox News, 1. januára 2018, <a href="http://www.foxnews.com/entertainment/2018/01/01/cnn-revels-in-pot-smoke-during-new-years-eve-report-from-denver.html.">http://www.foxnews.com/entertainment/2018/01/01/cnn-revels-in-pot-smoke-during-new-years-eve-report-from-denver.html.</a></p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Patrick McGreevy, „Billionaire Activists like Sean Parker and George Soros Are Fueling the Campaign to Legalize Pot“, Los Angeles Times, 2. novembra 2016, <a href="http://www.latimes.com/politics/la-pol-ca-proposition64-cash-snap-20161102-story.html.">http://www.latimes.com/politics/la-pol-ca-proposition64-cash-snap-20161102-story.html.</a></p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> Senátor Mike Lee, „GOP Senators Call on Sec. Tillerson to Investigate State Department Meddling“, 14. marca 2017, <a href="https://www.lee.senate.gov/public/index.cfm/press-releases?ID=B5BD5596-25C8-495F-A8B1-A4D248649C04"></a><a href="https://www.lee.senate.gov/public/index.cfm/press-releases?ID=B5BD5596-25C8-495F-A8B1-A4D248649C04">https://www.lee.senate.gov/public/index.cfm/press-releases?ID=B5BD5596-25C8-495F-A8B1-A4D248649C04</a>.</p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> Schlafly, The Supremacists.</p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> Obergefell v. Hodges, 576 U.S. 644 (2015).</p>
      <p><a href="#_ednref30" id="_edn30">[30]</a> Todd Starnes, „Kentucky Clerk: ,This is a fight worth fightingʻ“, Fox News, 3. septembra 2015, <a href="http://www.foxnews.com/opinion/2015/09/03/kentucky-clerk-am-">http://www.foxnews.com/opinion/2015/09/03/kentucky-clerk-am-</a> ready-to-go-to-jail.html.</p>
      <p><a href="#_ednref31" id="_edn31">[31]</a> Mike Huckabee, citované z: „Attorney for Kim Davis Speaks Out, Huckabee Blasts ,Judicial Overreach’ in Case”, rozhovor Seana Hannityho,&nbsp;Fox News,&nbsp;8. septembra 2015,&nbsp;<a href="http://www.foxnews.com/transcript/2015/09/08/attorney-for-kim-davis-speaks-out-huckabee-blasts-judicial-overreach-in-case.html"></a><a href="http://www.foxnews.com/transcript/2015/09/08/attorney-for-kim-davis-speaks-out-huckabee-blasts-judicial-overreach-in-case.html">http://www.foxnews.com/transcript/2015/09/08/attorney-for-kim-davis-speaks-out-huckabee-blasts-judicial-overreach-in-case.html</a>.</p>
      <p><a href="#_ednref32" id="_edn32">[32]</a> Stop the Kinsey Institute Coalition, „Kinsey Helped Undermine Laws Protecting Women &amp; Children“,&nbsp;<a href="http://stopthekinseyinstitute.org/more/undermining-laws/"></a><a href="http://stopthekinseyinstitute.org/more/undermining-laws/.">http://stopthekinseyinstitute.org/more/undermining-laws/.</a></p>
      <p><a href="#_ednref33" id="_edn33">[33]</a> Paul Rubin a kol.,„Does Capital Punishment Have a&nbsp;Deterrent Effect? New Evidence From Post-Moratorium Panel Data”, Clemson University a&nbsp;Emory University (október 2003),&nbsp;<a href="https://cjlf.org/deathpenalty/DezRubShepDeterFinal.pdf"></a><a href="https://cjlf.org/deathpenalty/DezRubShepDeterFinal.pdf">https://cjlf.org/deathpenalty/DezRubShepDeterFinal.pdf</a>.</p>
      <p><a href="#_ednref34" id="_edn34">[34]</a> Lawrence v. Texas, 539 USA 558 (2003).</p>
      <p><a href="#_ednref35" id="_edn35">[35]</a> Berman, The Interaction.</p>
      <p><a href="#_ednref36" id="_edn36">[36]</a> Skousen, The Naked Communist.</p>
      <Link to={`/kapitola_11`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


